@font-face {
  font-family: 'Inter';
  font-weight: normal;
  src: url('./assets/font/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: bold;
  src: url('./assets/font/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: lighter;
  src: url('./assets/font/Inter-Light.ttf');
}
@font-face {
  font-family: 'Inter-Medium';
  font-weight: 600;
  src: url('./assets/font/Inter-Medium.ttf');
}


body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.css-ka6i2q-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #000000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: inherit;
  color: inherit;
  transition: opacity 0.2s;
  cursor: pointer;
}
a:hover {
  opacity: 0.5;
}
a.aDefault {
  text-decoration: underline !important;
  color: blue;
  transition: opacity 0.2s;
}
li::marker {
  color: #F9CB48;
}

/* .MuiPaper-root {
  box-shadow: inherit !important;
  border: 1px solid black;
} */

.MuiPaper-root.Mui-expanded {
  border: none;
}

.MuiPaper-root.Mui-expanded>div:first-child {
  background-color: #F9CB48;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.MuiAccordionSummary-expandIconWrapper {
  >svg {
    width: 40px;
    height: 40px;
  }

  >svg>path {
    fill: #F9CB48 !important;
  }
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  >svg {
    width: 40px;
    height: 40px;
  }

  >svg>path {
    fill: black !important;
  }
}

.MuiInputBase-root.Mui-focused {
  > fieldset {

    border-color: black !important;
  }

}

.MuiInputBase-root.MuiOutlinedInput-root {
  > fieldset {

    border-color: black !important;
  }
}
.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 12px;
  input::placeholder, textarea::placeholder {
    color: black !important;
    opacity: 1;
  }
}

.select-style {
  > fieldset {
    border: none;
  }
  .MuiInputLabel-root {
    color: black !important;
  }
  .MuiInputLabel-root.Mui-focused {
    color: black !important;
  }
}

.select-style-gray {
  > div {
   background-color: #F1F1F1;
 }
 > fieldset {
   border: none;
 }
 .MuiInputLabel-root {
   color: black !important;
}
 .MuiInputLabel-root.Mui-focused {
   color: black !important;
}
}

.MuiListItem-root span {
  cursor:pointer;
  transition: opacity 0.2s;
}
.MuiListItem-root span:hover {
  opacity: 0.7;
}
.MuiListItem-root span:active {
  opacity: 0.3;
}